import { currency_list } from "src/utils/countryData";

export const bank_account_type = [
  "REVENUE",
  "INVEST",
  "LIQUIDITY",
  "OPERATING",
  "COLLATERAL",
  "SAFEGUARDING",
  "PAYROLL",
  "CLIENT",
  "GCA",
  "LOCAL",
  "FEES",
  "FD",
];
export const owner_type = ["FINMO", "CLIENT"];

export const tableCustomColumn = [
  {
    columnName: "Bank Account ID",
    dataKey: ["bank_account_id"],
    isVisible: true,
    is_tooltip_enabled: true,
    limit: 16,
  },

  {
    columnName: "Bank Account Name",
    dataKey: ["account_name"],
    isVisible: true,
  },
  {
    columnName: "Bank",
    dataKey: ["bank_name"],
    isVisible: true,
  },

  {
    columnName: "Local Currency",
    dataKey: ["currency"],
    isVisible: true,
  },
  {
    columnName: "Account Number",
    dataKey: ["account_number"],
    isVisible: true,
  },

  {
    columnName: "Bank Account Type",
    dataKey: ["bank_account_type"],
    isVisible: true,
    isLabel: true,
  },

  {
    columnName: "Status",
    dataKey: ["status"],
    isVisible: true,
    isLabel: true,
  },
  {
    columnName: "Created At",
    dataKey: ["created_at"],
    isVisible: true,
  },
  {
    columnName: "Updated At",
    dataKey: ["updated_at"],
    isVisible: false,
  },
];

export const filterOptions = [
  {
    key: "bank_account_id",
    label: "Bank Account ID",
  },
  {
    key: "account_number",
    label: "Account Number",
  },
  {
    key: "account_name",
    label: "Bank Account Name",
  },
  {
    key: "currency",
    label: "Local Currency",
  },
  {
    key: "bank_account_type",
    label: "Bank Account Type",
  },

  {
    key: "status",
    label: "Status",
  },
  {
    key: "org_id",
    label: "Org ID",
  },
];

export const filterKeyOptions = {
  bank_account_type: [
    { label: "OPERATING", value: "OPERATING" },
    { label: "LIQUIDITY", value: "LIQUIDITY" },
    { label: "REVENUE", value: "REVENUE" },
    { label: "INVEST", value: "INVEST" },
    { label: "COLLATERAL", value: "COLLATERAL" },
    { label: "SAFEGUARDING", value: "SAFEGUARDING" },
    { label: "PAYROLL", value: "PAYROLL" },
    { label: "CLIENT", value: "CLIENT" },
    { label: "GCA", value: "GCA" },
    { label: "LOCAL", value: "LOCAL" },
    { label: "FEES", value: "FEES" },
    { label: "FD", value: "FD" },
  ],
  status: [
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ],
  currency: currency_list.filter((currency) => currency.value !== "*"),
};

export const csvHeader = [
  { label: "Bank Account ID", key: "bank_account_id" },
  { label: "Bank", key: "bank_name" },
  { label: "Account Holder Name", key: "account_holder_name" },
  { label: "Bank Account Name", key: "account_name" },
  { label: "Local Currency", key: "currency" },
  { label: "Account Number", key: "account_number" },
  { label: "Bank Account Type", key: "bank_account_type" },
  { label: "Currency", key: "currency" },
  { label: "Status", key: "status" },
  { label: "Scope", key: "scope" },
  { label: "Org ID", key: "org_id" },
  { label: "Created At", key: "created_at" },
  { label: "Updated At", key: "updated_at" },
];

export const bank_map = ["bank_7bbce126ab5b40cf8ddf1768fb2107c1"];
